.orcamentoform {
  .nivel1 {
    background-color: rgb(225, 233, 243);
  }
  .nivel2 {
    background-color: rgb(244, 246, 249);
    text-transform: uppercase;
  }
  .nivel3 {
    font-style: italic;
    text-transform: uppercase;
    &.servico {
      color: darkgreen;
    }
  }
}
