.diario {
  margin: 20px;
  padding: 15px;
  .header-table {
    width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid;
      padding: 10px;
      .header-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        .header-data-item {
          display: inline-flex;
          .header-data-item-label {
            width: 100px;
            text-align: right;
            font-weight: bold;
            &::after {
              content: ':';
            }
          }
          .header-data-item-value {
            width: 200px;
            text-align: left;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    border: 1px solid;
    background-color: lightgrey;
  }
  .situacao-table {
    width: 100%;
    border: 1px solid;
    thead {
      border-bottom: 1px solid grey;
    }
  }
  .info-table {
    width: 100%;
    border: 1px solid;
    thead {
      border-bottom: 1px solid grey;
    }
    tbody {
      tr {
        border-bottom: 1px solid lightgrey;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .observacoes {
    min-height: 100px;
    border: 1px solid;
    page-break-after: always;
  }
  .fotos {
    margin: 5px;
    display: flex;
    justify-content: center;
    gap: 5px 5px;
    flex-wrap: wrap;
    .foto {
      padding: 5px;
      border: 1px solid;

      img {
        width: 400px;
        page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-inside: avoid; /* or 'auto' */
      }
    }
  }
  @media print {
    footer {
      page-break-after: always;
    }
  }
}
