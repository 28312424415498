.curvaABCTable {
  .curvaA {
    td {
      &:last-child {
        background-color: lightgreen;
      }
    }
  }
  .curvaB {
    td {
      &:last-child {
        background-color: lightblue;
      }
    }
  }
  .curvaC {
    td {
      &:last-child {
        background-color: lightyellow;
      }
    }
  }
}
