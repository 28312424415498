.ordemprint {
  background-color: white;
  table {
    width: 100%;
  }
  td {
    padding: 5px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    .header-data {
      display: flex;
      flex-direction: column;
      .header-item {
        display: inline-flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
        &:last-child {
          border-bottom: none;
        }
        .header-item-label {
          width: 100px;
          text-align: right;
          font-weight: bold;
          &::after {
            content: ':';
          }
        }
        .header-item-value {
          width: 300px;
          margin-left: 8px;
        }
      }
    }
  }
  .geral {
    display: inline-flex;
    border: 1px solid;
    width: 100%;
    .fornecedor {
      display: flex;
      flex-direction: column;
      flex: 1 1 50%;
      .fornecedor-title {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid;
        background-color: lightgrey;
      }
      .fornecedor-item {
        display: inline-flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
        &:last-child {
          border-bottom: none;
        }
        .fornecedor-item-label {
          min-width: 80px;
          text-align: right;
          font-weight: bold;
          &::after {
            content: ':';
          }
        }
        .fornecedor-item-value {
          margin-left: 8px;
        }
      }
    }
    .pagamento {
      display: flex;
      flex-direction: column;
      flex: 1 1 50%;
      .pagamento-title {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid;
        background-color: lightgrey;
      }
      .pagamento-item {
        display: inline-flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
        &:last-child {
          border-bottom: none;
        }
        .pagamento-item-label {
          min-width: 140px;
          text-align: right;
          font-weight: bold;
          &::after {
            content: ':';
          }
        }
        .pagamento-item-value {
          margin-left: 8px;
        }
      }
    }
    .faturamento {
      display: flex;
      flex: 1 1 50%;
      flex-direction: column;
      .faturamento-title {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid;
        background-color: lightgrey;
      }
      .faturamento-item {
        display: inline-flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
        &:last-child {
          border-bottom: none;
        }
        .faturamento-item-label {
          min-width: 80px;
          text-align: right;
          font-weight: bold;
          &::after {
            content: ':';
          }
        }
        .faturamento-item-value {
          margin-left: 8px;
        }
      }
    }
  }

  .itens {
    border: 1px solid;

    .itens-title {
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
      background-color: lightgrey;
    }
    table {
      border: none;
    }
    tr {
      border-bottom: 1px solid lightgrey;

      th {
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-left: none;
        &:last-child {
          border-right: none;
        }
      }
      td {
        padding: 3px;
        border-left: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-right: 1px solid lightgrey;
        &:last-child {
          border-right: none;
        }
      }
      .ordem-value-label {
        font-weight: bold;
        text-align: right;
      }
      .value {
        text-align: right;
      }
    }
  }
  .subtitle {
    font-weight: bold;
    border-bottom: 1px solid;
    text-align: center;
    background-color: lightgrey;
  }
  .section {
    border: 1px solid;
  }
}
