.pedidoView-header {
  background-color: white;
  th {
    border: 1px solid grey;
    padding: 3px;
    &.title {
      font-size: x-large;
    }
  }
}
.pedidoView-body {
  background-color: white;
  th,
  td {
    border: 1px solid grey;
    padding: 3px;
    &.value {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
  }
}
