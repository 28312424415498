.main-table {
  border: 1px solid black;
  thead {
    background-color: grey;
    color: white;
    tr {
      th {
        white-space: nowrap;
      }
    }
  }
  tbody {
    font-weight: bold;
    tr {
      background-color: white;
      border-bottom: 1px solid;
      &:last-child {
        border-bottom: none;
      }
      td {
        white-space: nowrap;
        &.value {
          text-align: right;
        }
      }
    }
  }
  .ordens-table {
    width: 100%;
    thead {
      tr {
        background-color: rgb(28, 169, 215);
        color: white;
        th {
          border-right: 1px solid;
          padding: 5px;
        }
      }
    }
    tbody {
      font-weight: normal;
      tr {
        background-color: whitesmoke;
        &:nth-child(even) {
          background-color: rgb(203, 203, 203);
        }
        td {
          padding: 5px;
        }
      }
    }
  }
}
