@import 'antd/dist/antd.compact.css';
.ant-layout-header {
  background-color: white;
}
.subtitulo {
  font-weight: 800;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.back {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  height: 100vh;
  width: 350px;
  opacity: 0.2;
  position: fixed;
}
.loginbox {
  margin: 20px;
  border-radius: 2px 2px 2px 2px;
  border: grey;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
}
.naoorcado {
  background-color: rgb(255, 185, 185);
}
.nivel1 {
  font-weight: 600;
  text-transform: uppercase;
}
.cartavencera {
  background-color: yellow;
}
.cartavenceu {
  background-color: red;
}
.servico {
  color: darkgreen;
  font-weight: 600;
}

.printpage * {
  font-size: xx-small;
}
.printpageCarta * {
  font-size: 12px;
}
pagebreak {
  page-break-after: always;
}
.onprint {
  display: none;
}
.ordem * {
  border: 0.1px solid grey;
  text-align: center;
}
.ordem th {
  background-color: rgb(241, 241, 241);
  padding-left: 8px;
  padding-right: 8px;
  padding: 3px;
}
.grupo {
  font-weight: 800;
}
@media print {
  @page {
    size: auto; /* auto is the initial value */
    /* this affects the margin in the printer settings */
    margin: 10mm 10mm 10mm 10mm;
  }
  .pagebreak {
    page-break-after: always;
  }
  .onprint {
    display: block;
  }
  * {
    font-size: 9px;
  }
  td > strong {
    font-size: 9px;
  }
  thead {
  }
  .ordem > * {
    font-size: 4px;
  }
  tr {
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .xs,
  .s {
    display: none;
  }
  .imggallery {
    width: 320px;
  }
  .ant-modal-content {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .s {
    display: none;
  }
  .imggallery {
    width: 480px;
  }
  .ant-modal-content {
    width: 480px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .s {
    display: none;
  }
  .imggallery {
    width: 480px;
  }
  .ant-modal-content {
    max-width: 480px;
  }
}

@media only screen and (min-width: 1200px) {
  .imggallery {
    width: 480px;
  }
  .ant-modal {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
}
.ant-layout-sider {
  display: fixed;
  max-height: calc(100vh - 64px);
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.19);
}
.ant-layout-header {
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.19);
}
.ant-layout-sider-children {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}
