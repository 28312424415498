.print-table-header {
  width: 100%;
  thead {
    tr {
      th {
        border: 1px solid lightgrey;
        &:first-child {
          width: 350px;
        }
        &.title {
          font-size: larger;

          text-transform: uppercase;
        }
      }
    }
  }
}
.print-table-body {
  width: 100%;
  thead {
    tr {
      th {
        padding: 3px;
        border: 1px solid lightgrey;
        &.center {
          text-align: center;
        }
        &.left {
          text-align: left;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 3px;
      }
      &:nth-child(even) {
        background-color: lightgrey;
      }
    }
  }
  tfoot {
    font-weight: bold;
  }
  td {
    white-space: nowrap;
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
  }
}
