.receipt-main {
  display: inline-block;
  width: 100%;
  padding: 15px;
  font-size: 12px;
  border: 1px solid #000;
}

.receipt-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.receipt-label {
  font-weight: 600;
}

.text-large {
  font-size: 16px;
}
.text-main {
  font-size: 12px;
}

.receipt-section {
  margin-top: 10px;
}

.receipt-footer {
  text-align: center;
  background: #ff0000;
}
.valor {
  border: 1px solid #000;
  text-align: right;
}
.data {
  text-align: right;
  padding-right: 50px;
}
.receipt-signature {
  height: 80px;
  margin: 50px 0;
  padding: 0 50px;
  background: #fff;
}
.text-signature {
  text-align: center;
}
.receipt-line {
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
}

p {
  margin: 0;
}
strong {
  font-size: 12px;
}
